<template lang="pug">
v-container(fluid, v-if="$store.state.app.accessSySafra")
  v-expansion-panels
    v-expansion-panel
      v-expansion-panel-header
        span.title Conta do Storage de Backup
      v-expansion-panel-content
        v-card.ma-2(elevation="0")
          v-layout(row, wrap)
            v-flex(xs12, sm6, md4, lg3, xl2)
              v-text-field.mx-1(
                :disabled="notGerente",
                label="Projeto",
                v-model="config.projeto",
                dense,
                outlined
              )
            v-flex(xs12, sm6, md4, lg3, xl2)
              v-text-field.mx-1(
                :disabled="notGerente",
                label="Usuário admin",
                v-model="config.usuarioadmin",
                dense,
                outlined
              )
            v-flex(xs12, sm6, md4, lg6, xl2)
              v-text-field.mx-1(
                :disabled="notGerente",
                label="Credenciais",
                type="password",
                v-model="config.credenciais",
                dense,
                outlined
              )
          v-layout(row, wrap, justify-end)
            v-btn.mr-2(
              color="error",
              :disabled="notGerente",
              outlined,
              @click="cancelConfig"
            ) Cancelar
            v-btn(color="success", :disabled="notGerente", @click="saveConfig") Salvar

    v-expansion-panel
      v-expansion-panel-header
        span.title Regra padrão de validação dos Backups
      v-expansion-panel-content
        v-card.ma-2(elevation="0")
          v-layout(row, wrap)
            v-flex(xs12, sm6, md4, lg3, xl2)
              v-select.mx-1(
                :disabled="notGerente",
                label="Tipo de base",
                :items="tipoBase",
                v-model="validacao.tipobase",
                dense,
                outlined
              )
            v-flex(xs12, sm6, md4, lg3, xl2)
              v-select.mx-1(
                :disabled="notGerente",
                label="Período",
                :items="tipoPeriodo",
                v-model="validacao.periodo",
                dense,
                outlined
              )
          v-layout(row, wrap, justify-end)
            v-btn.mr-2(
              color="error",
              outlined,
              :disabled="notGerente",
              @click="cancelValidacao"
            ) Cancelar
            v-btn(
              color="success",
              :disabled="notGerente",
              @click="saveValidacao"
            ) Salvar
</template>

<script>
import csapi from "@/api/csapi";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      notGerente: true,
      tipoBase: ["NORMAL", "LOG", "AMBAS"],
      tipoPeriodo: ["DIARIO", "SEMANAL"],
      config: {
        projeto: null,
        credenciais: null,
        usuarioadmin: null,
      },
      validacao: {
        tipobase: null,
        periodo: null,
      },
    };
  },
  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    async getConfig() {
      const res = await csapi.get("/sysafra/v1/sysbackup/config");

      if (res.status == 200) {
        this.config = res.data;
      }
    },
    async getConfigValidacao() {
      const res = await csapi.get("/sysafra/v1/sysbackup/config/validacao");

      if (res.status == 200) {
        this.validacao = res.data;
      }
    },

    async getConfigs() {
      await Promise.all([this.getConfig(), this.getConfigValidacao()]);
    },
    cancelConfig() {
      this.getConfigs();
    },
    async saveConfig() {
      const res = await csapi.post("/sysafra/v1/sysbackup/config", this.config);

      if (res.status == 200) {
        this.$toast.success("Configurações salvas com sucesso!");
        this.getConfigs();
      } else {
        this.$toast.error("Erro ao salvar configurações!");
      }
    },
    cancelValidacao() {
      this.getConfigs();
    },
    async saveValidacao() {
      const res = await csapi.post(
        "/sysafra/v1/sysbackup/config/validacao",
        this.validacao
      );

      if (res.status == 200) {
        this.$toast.success("Configurações salvas com sucesso!");
      } else {
        this.$toast.error("Erro ao salvar configurações!");
      }
    },
  },

  created() {
    if (!this.$store.state.app.accessSySafra) {
      this.$store.state.app.redirectToControl();
    }
  },

  mounted() {
    if (!this.$store.state.app.accessSySafra) return;

    const roles = JSON.parse(JSON.stringify(this.$store.state.auth.user.roles));
    this.notGerente =
      roles.filter((role) => role.projectid == 250 && role.id == 3).length == 0;

    this.isControl(false);
    this.setTitle("SySBackup - Configuração padrão");
    this.getConfigs();
  },
};
</script>
